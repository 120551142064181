var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    color: "primary",
                                    icon: ""
                                  }
                                },
                                Object.assign({}, tooltip, dialog)
                              ),
                              [_c("v-icon", [_vm._v("create_new_folder")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c("span", [_vm._v("Create Folder")])]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.newFolderDialog,
        callback: function($$v) {
          _vm.newFolderDialog = $$v
        },
        expression: "newFolderDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "title lighten-2 primary--text" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-1", attrs: { color: "primary" } },
                  [_vm._v("create_new_folder")]
                ),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v("new folder")
                ])
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Folder name")
              ]),
              _c("v-text-field", {
                attrs: { label: "Folder name here" },
                model: {
                  value: _vm.folderName,
                  callback: function($$v) {
                    _vm.folderName = $$v
                  },
                  expression: "folderName"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.newFolderDialog = false
                    }
                  }
                },
                [_vm._v("cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.folderName.length,
                    text: "",
                    color: "secondary"
                  },
                  on: { click: _vm.createFolder }
                },
                [_vm._v("create folder")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }