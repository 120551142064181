<template>
    <v-dialog v-model="newFolderDialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn :disabled="disabled" color="primary" icon v-on="{ ...tooltip, ...dialog }">
                        <v-icon>create_new_folder</v-icon>
                    </v-btn>
                </template>
                <span>Create Folder</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <div class="title lighten-2 primary--text">
                    <v-icon color="primary" class="mr-1">create_new_folder</v-icon>
                    <span class="text-uppercase">new folder</span>
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text class="mt-1">
                <span class="font-weight-bold">Folder name</span>
                <v-text-field label="Folder name here" v-model="folderName"> </v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mr-1" text @click="newFolderDialog = false">cancel</v-btn>
                <v-btn :disabled="!folderName.length" @click="createFolder" text color="secondary">create folder</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'CreateFolder',
    mixins: [enumsData],
    data: function() {
        return {
            newFolderDialog: false,
            folderName: ''
        }
    },
    props: {
        path: String,
        disabled: Boolean
    },
    methods: {
        createFolder() {
            const apiURL = this.$props.path
            const postBody = {
                short_id: this.$data.folderName,
                long_id: this.$data.folderName
            }
            this.$axios
                .post(apiURL, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Folder created successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to create folder`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.folderName = ''
                    this.$data.newFolderDialog = false
                })
        }
    }
}
</script>
